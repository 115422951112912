import React from "react";
import config from "../../data/SiteConfig";
import Seo from "../components/Seo/Seo";
import Layout from "../layout";
import Activities from "../components/Activities/Activities";
import Contact from "../components/Contact/Contact";
import GImage from "../components/Picture/GImage";
import { graphql } from "gatsby";

const ActivitiesPage = ({ data: gqlData }) => {
  const { directus } = gqlData;
  return (
    <Layout>
      <div className="h-20 md:h-28"></div>
      {directus.settings.activities_illustration && (
        <GImage
          className="h-44 md:h-72 bg-activities bg-cover"
          src={directus.settings.activities_illustration.imageFile}
          alt="Image de la page des activités du secteur langues du Groupe Français d'éducation Nouvelle"
        />
      )}
      <div className="activities-container">
        <Seo
          title={`Nos activités`}
          description={config.siteDescription3}
          article={false}
        />
        <Activities
          title={directus.settings.activities_title}
          description={directus.settings.activities_description}
          evenements={directus.evenements}
          reunions={directus.reunions}
          page={true}
        />
        {directus.contact && (
          <Contact contact={directus.contact} background={true} />
        )}
      </div>
    </Layout>
  );
};

export default ActivitiesPage;

export const pageQuery = graphql`
  query {
    directus: allDirectus {
      settings {
        id
        activities_title
        activities_description
        activities_illustration {
          id
          imageFile {
            id
            childImageSharp {
              id
              gatsbyImageData(
                quality: 80
                layout: FULL_WIDTH
                placeholder: TRACED_SVG
                tracedSVGOptions: { color: "#d8b0b1" }
              )
            }
          }
        }
      }
      evenements: activities(
        sort: ["-date_from", "-date_to"]
        filter: { type: { _eq: "Évènement" } }
      ) {
        id
        title
        type
        date_from
        date_to
        meetings
        location
        intro
        description
        documents {
          directus_files_id {
            id
          }
        }
      }
      reunions: activities(
        sort: ["-date_from", "-date_to"]
        filter: { type: { _eq: "Réunion" } }
      ) {
        id
        title
        type
        date_from
        date_to
        meetings
        location
        intro
        description
        documents {
          directus_files_id {
            id
          }
        }
      }
      contact {
        title
        subtitle
        intro
        informations
        illustration {
          id
          imageFile {
            id
            childImageSharp {
              id
              gatsbyImageData(
                quality: 80
                layout: FULL_WIDTH
                placeholder: TRACED_SVG
                tracedSVGOptions: { color: "#d8b0b1" }
              )
            }
          }
        }
      }
    }
  }
`;
